export default {
  name: 'account',
  components: {},
  props: [],
  data () {
    return {
      logoutButtonState: "idle"
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
  },
  mounted () {
    console.log("account mounted")
  },
  methods: {
    signOut () {
      console.log("user is signing out")
      if(this.logoutButtonState === 'loading') return;
      
      this.logoutButtonState = "loading"
      this.$store.dispatch('signOut')
        .then(data=>{
          console.log('data:', data);
          this.$router.push('/login')
        })
        .catch(e=>{
          console.log("error signing out:", e)
          this.$toast.error(`Error signing out: ${e}`)
        })
        .finally(() => {
          this.logoutButtonState = 'idle'
        })
    },
  }
}
